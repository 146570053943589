import { Link } from 'gatsby'
import { default as React } from 'react'
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from 'react-instantsearch-dom'
import tw, { theme, css } from 'twin.macro'

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div
      tw="font-sans pl-6 md:pl-6 py-1 md:py-1.5 text-xs bg-secondary text-softer bg-opacity-40 text-opacity-40"
    >
      {searchResults.index === 'Pages' ? `Pages` : `Learnings, Notes & Reviews`}
    </div>
  ) : null
})

const PageHit = ({ hit }) => {
  return (
    <Link tw="block m-2 md:m-3" to={hit.slug}>
      <div
        tw="flex flex-col p-4 md:p-3 rounded md:h-24 hover:bg-lighter hover:shadow-sm hover:text-primary text-opacity-80"
        css={{
          '&:hover div, &:hover span': {
            '--tw-text-opacity': 1,
            color: `${theme`textColor.primary`}`,
          },
          '&:hover div': {
            color: `${theme`backgroundColor.lighter`}`,
            borderRadius: 2,
          },
        }}
      >
        <div tw="text-base lg:text-lg text-softer hover:text-primary">
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </div>
        <Snippet
          tw="text-sm text-softer font-normal"
          attribute="excerpt"
          hit={hit}
          tagName="mark"
          css={{
            opacity: 0.6,
          }}
        />
      </div>
    </Link>
  )
}

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits hitComponent={PageHit} />
  </Index>
)

const SearchResult = ({ indices }) => {
  return (
    <div
      className="group"
      tw="scrollbar-thin"
      css={{
        height: '100vh',
        paddingBottom: '12rem',
      }}
    >
      {indices.map(index => (
        <HitsInIndex index={index} key={index.name} />
      ))}
      <div tw="flex flex-row justify-center px-2 font-sans">
        <PoweredBy
          css={{
            '> span': {
              lineHeight: 2.1,
            },
            svg: {
              width: 64,
            },
          }}
          tw="flex flex-wrap content-center text-xs align-middle gap-x-1.5 text-softer"
        />
      </div>
    </div>
  )
}

export default SearchResult

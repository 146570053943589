import { Link } from 'gatsby'
import React from 'react'
import tw, { theme, css } from 'twin.macro'
import Modal from './dialog'
import DarkModeToggle from './darkModeToggle'

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: 'active' } : null
}

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: 'active' } : null
}

const Header = ({ siteTitle, SkipNavLink }) => {
  return (
    <nav
      tw="w-full md:top-0 md:fixed md:shadow-sm md:z-10 bg-primary border-b border-opacity-5 grid"
      css={{
        borderColor: `${theme`borderColor.accent`}`,
        opacity: 0.9,
        gridTemplateColumns: '1fr min(1024px, calc(100% - 48px)) 1fr',
        gridRowGap: 8,
        '& > *': {
          gridColumn: 2,
        },
      }}
    >
      <div
        tw="grid grid-cols-2 md:grid-cols-4"
        css={{
          'a h2:hover': {
            color: `${theme`colors.primary`}`,
          },
          'a li:hover': {
            color: `${theme`colors.primary`}`,
          },
          'a.active li': {
            borderBottomColor: `${theme`colors.accent`}`,
            borderBottomWidth: 2,
            marginBottom: 0,
            color: `${theme`colors.accent`}`,
            fontWeight: 600,
          },
          'a.active h2': {
            fontWeight: 600,
          },
        }}
      >
        <section tw="contents">
          <div tw="flex flex-row-reverse justify-end items-stretch md:px-0">
            <SkipNavLink
              css={{
                border: 0,
                clip: 'rect(0 0 0 0)',
                height: 1,
                width: 1,
                margin: -1,
                padding: 0,
                overflow: 'hidden',
                position: 'absolute',
                '&:focus': {
                  zIndex: '1',
                  width: 'auto',
                  height: 'auto',
                  clip: 'auto',
                  position: 'relative',
                },
              }}
              tw="justify-self-center self-center ml-4 mt-1 md:mt-0 px-1 py-0.5"
            />
            <Link to="/" activeClassName="active" getProps={isActive}>
              <h2 tw="pt-4 pb-2 md:pb-3 font-serif text-opacity-70 text-lg">{siteTitle}</h2>
            </Link>
          </div>
          <div tw="flex flex-row-reverse space-x-3 space-x-reverse items-center md:order-last leading-none">
            <Modal />
            <DarkModeToggle />
          </div>
        </section>
        <section tw="md:ml-auto md:mr-auto md:col-start-2 md:col-span-2">
          <ul tw="text-center md:text-right font-serif text-base md:text-lg flex space-x-5 md:space-x-8">
            <Link to="/projects/" activeClassName="active" getProps={isPartiallyActive}>
              <li tw="pt-2 md:pt-4 pb-2 md:pb-3 border-b-2 border-transparent">Projects</li>
            </Link>
            <Link to="/work/" activeClassName="active" getProps={isPartiallyActive}>
              <li tw="pt-2 md:pt-4 pb-2 md:pb-3 border-b-2 border-transparent">Work</li>
            </Link>
            <Link to="/about/" activeClassName="active" getProps={isPartiallyActive}>
              <li tw="pt-2 md:pt-4 pb-2 md:pb-3 border-b-2 border-transparent">About</li>
            </Link>
            {/* <Link to="/reviews/" activeClassName="active" getProps={isPartiallyActive}>
              <li tw="pt-2 md:pt-4 pb-2 md:pb-3 border-b-2 border-transparent">Reviews</li>
            </Link> */}
            <Link to="/notes/" activeClassName="active" getProps={isPartiallyActive}>
              <li tw="pt-2 md:pt-4 pb-2 md:pb-3 border-b-2 border-transparent">Notes</li>
            </Link>
            <Link to="/learn/" activeClassName="active" getProps={isPartiallyActive}>
              <li tw="pt-2 md:pt-4 pb-2 md:pb-3 border-b-2 border-transparent">Learn</li>
            </Link>
          </ul>
        </section>
      </div>
    </nav>
  )
}

export default Header
